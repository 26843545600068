import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import * as moment from "moment";
import 'moment/locale/es';
import { HomeService } from "../../home.service";
import { setting } from "../../../../../setting";
import { LoginService } from "../../login/login.service";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Observable, Subscription } from "rxjs";


@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  providers: [HomeService],
})
export class HeaderComponent implements OnInit, OnDestroy  {
  @Input() id: number;
  @Input() change_password: number;

  fechaa = moment().format("Do MMMM YYYY");
  menuHidden = true;
  isCollapsed = false;
  public getClientsSettingAppAuth: any;
  public sectionHeader: any;
  public routeUploadFile:any;
  headerClass$ = new BehaviorSubject<string>('');
  headerClass: string = '';
  logoPath$ = new BehaviorSubject<string>('');
  showButton$ = new BehaviorSubject<boolean>(true);
  showHomeIcons$ = new BehaviorSubject<boolean>(true);
  showSelectIcons$ = new BehaviorSubject<boolean>(false);
  public user: any;
  public isOpenOption: boolean = false;
  public hoverColor: string = '';
  public colorWhite: string = '#ffffff';
  public changePassModal: boolean = false;
  public isClickedInside: boolean = false;
  public changePassword: boolean = false;
  public documentClickHandler: any;
  fadeOut: boolean = false;
  public wsp_number: string;
  public pdfHelp: string;
  private logoutSubscription: Subscription;
  private routeEventsStyleSubscription: Subscription;
  private routeEventsLogotypeSubscription: Subscription;
  private routeEventsButtonSubscription: Subscription;
  private routeEventsHomeIconsSubscription: Subscription;

  constructor(private router: Router,
     private _loginService: LoginService,
     public _router: Router,
     private toastr: ToastrService,
     private cdr: ChangeDetectorRef
     ) {


  }

  ngOnInit() {

    this.getClientsSettingAppAuth = JSON.parse(localStorage.getItem(setting.name));
    this.routeUploadFile =  setting.routeUploadFile;
    this.sectionHeader = JSON.parse(this.getClientsSettingAppAuth.clients_setting_app_auth.section_header);
    moment.locale('es');
    let wspNumber = this.getClientsSettingAppAuth?.wsp_phone_number;

    // Eliminar el signo + y los espacios en blanco
    wspNumber = wspNumber.replace(/\+/g, '').replace(/\s/g, '');

    this.wsp_number = wspNumber;

    const concatPdf = setting.apiStorageBooking;
    // console.log('concatPdf', concatPdf + "manuales/Manual_Booking.pdf");
    this.pdfHelp = concatPdf + "manuales/Manual_Auth.pdf";

/*------------- Instance change style, logo and icon of route  -----------------*/

      this.headerClass$.next(this.sectionHeader.background_1);
      this.logoPath$.next(this.routeUploadFile + this.sectionHeader.logotype_header_1);
      this.showButton$.next(true);
      // Detectar la ruta actual al cargar la página
      this.updateHeaderStyle(this.router.url);

      // Suscribirse a los cambios de ruta
      this.routeEventsStyleSubscription = this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.updateHeaderStyle(event.url);
        }
 });

    setTimeout(() => {
      if(this.change_password === 0) {
        this.changePassword = true;
      } else {
        this.changePassword = false;
      }
    }, 1500);

  }

  // Método reutilizable para actualizar los valores según la ruta
private updateHeaderStyle(url: string) {
  if (url === '/home') {
    this.headerClass$.next(this.sectionHeader.background_1);
    this.logoPath$.next(this.routeUploadFile + this.sectionHeader.logotype_header_1);
    this.showButton$.next(true);
    this.showHomeIcons$.next(true);
    this.showSelectIcons$.next(false);
  } else if (url === '/select') {
    this.headerClass$.next(this.sectionHeader.background_2);
    this.logoPath$.next(this.routeUploadFile + this.sectionHeader.logotype_header_2);
    this.showButton$.next(false);
    this.showHomeIcons$.next(false);
    this.showSelectIcons$.next(true);
  }
}

  onLogout() {
    this.user =localStorage.getItem("user");
    let data = {
      email: this.user.email,
      auth_token: this._loginService.getCookie("auth_token"),
    };

this.logoutSubscription = this._loginService.logout(data).subscribe(
      (data: any) => {
        if (data.res == 1) {
          this._loginService.setCookie("auth_token", "", -1, setting.c_domain);
          // this.toastr.success("Hasta Pronto !!", "");
          this._router.navigate(["/home", {}]);
        } else {
          this._loginService.setCookie("auth_token", "", -1, setting.c_domain);
          this._router.navigate(["/home", {}]);
        }
      },
      (error) => {
        this.toastr.error(JSON.parse(error._body).det,'Error');
      }
    );
  }

  toggleChangeOption() {
    this.isOpenOption = !this.isOpenOption;
  }

  setHoverColor(color: string) {
    this.hoverColor = color;
  }

  openChangePass() {
    this.isOpenOption = true;
    this.changePassModal = true;
    this.isClickedInside = true;
  }

  closeChangePass() {
    this.changePassModal = false;
    this.isOpenOption = false;
    this.isClickedInside = false;
  }

  onClick() {
    if (!this.isClickedInside) {
      this.closeChangePass();
    }
    this.isClickedInside = false;
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.documentClickHandler);
    // this.logoutSubscription.unsubscribe();
    // this.routeEventsStyleSubscription.unsubscribe();
    // this.routeEventsLogotypeSubscription.unsubscribe();
    // this.routeEventsButtonSubscription.unsubscribe();
    // this.routeEventsHomeIconsSubscription.unsubscribe();
  }

  fadeOutAnimation() {
    this.fadeOut = true;
    setTimeout(() => {
      this.fadeOutAnimationComplete();
    }, 3500);

  }

  fadeOutAnimationComplete() {
    this.changePassModal = false;
    this.changePassword = false;
    this.isOpenOption = false;
  }

  closeChangePassword() {

    setTimeout(() => {
      if(this.change_password === 0) {
        this.changePassword = true;
      } else {
        this.changePassword = false;
      }
    }, 1000);
    this.changePassModal = false;
    this.isOpenOption = false;
  }

}
