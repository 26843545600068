import { Router, Params, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { LoginService } from './login.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { setting } from '../../../../setting';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
	selector: 'login',
	templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
	providers: [LoginService]
})

export class LoginComponent implements OnInit{
	public getClientsSettingAppAuth: any;
	public sectionLogin: any;
	public load : boolean;
	public modules:any;
	public urls: any;
	public loginForm : FormGroup;
	public activeform : any;
	private user : any;
	public dbAgency: any;
	modalRef: BsModalRef;
	public agencysUser;
	apiBookingUrl:any;
  showPassword: boolean = false;
	public data;
  public actionRecover: string;
  showModal = false;
  public loadingResetPass: boolean = true;
  public userDisabled: boolean = false;
	constructor(
		private _loginService: LoginService,
		public _router:Router,
		public activatedRoute: ActivatedRoute,
		private fb : FormBuilder,
		private modalService: BsModalService,


	){
		this.apiBookingUrl  = setting.apiBookingUrl

		this.getClientsSettingAppAuth = JSON.parse(localStorage.getItem(setting.name));
		this.load = false;
		this.activeform = {login:true,recoverpass:false};
		this.loginForm = this.fb.group({
	      email: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
	      password: ['', [Validators.required, Validators.minLength(6)]]
	    });


	}

	@ViewChild('ModalSelectAgency', { read: TemplateRef }) ModalSelectAgency;

	ngOnInit () {
		this.initUser(); // get the user info.

		// console.log("api booking url", this.apiBookingUrl);
		// this.sectionLogin = JSON.parse(this.getClientsSettingAppAuth.clients_setting_app_auth.section_login);
	}

	openModal() {
		setTimeout(() => {
		  this.modalRef = this.modalService.show(this.ModalSelectAgency,
			{ class: 'modal-lg',
			ignoreBackdropClick: true,
			keyboard: false
		});
		}, 500);
	}

	getAgency(_data: any, data: any) {
		this._loginService.getAgency(_data, data).subscribe(
			(response:any) => {
			//    this.dbAgency = response.data;
					if (response.length > 1 ) { // posee mas de una agencia
						this.agencysUser = response;
						this.data = _data;
					this.openModal();
					} else {
						//this.toastr.success('You have logged in successfully!','Success!');
						this._router.navigate(['/select', {}]);  // redirects to home with toast
						///this.dbAgency = response.data[0].name;
					}
			},
			error => {
				this.load = false;
			///	//this.toastr.error(JSON.parse(error._body).det, 'Error');
				//this.logger.log(error);
			}
		);
	}


	onLogin() {
    this.userDisabled = false;
		this.load = true; // loading screen
		this._loginService.login(this.loginForm.value).subscribe({
			next: (data:any) => {
				if(data.res == 1) {
					// this._loginService.setCookie('auth_token', data.token, null, setting.c_domain );
          this._loginService.setCookie('auth_token', data.token, null, setting.c_domain, true, true);
					var query = this.activatedRoute.snapshot.queryParamMap.get('redirect');

					query = decodeURIComponent(query);
					if (query && query!="null") {
					 	window.location.href = query;
					}else {
						//temporal
						this._router.navigate(['/select', {}]);  // redirects to home with toast
				   //  comentado temporal this.getAgency(this._loginService.getCookie('auth_token'), data); /// verificar si hay mas una agencia
					}
				} else {
          this.userDisabled = true;
          this.openModalError();
				}
				this.load = false;
			},
			error: (error: HttpErrorResponse )=> {
        this.openModalError();
			//	this.toastr.error(JSON.parse(error._body).det,'Error');
			//	this.logger.log(error);
				this.load = false;
			}
		});
	}

  openModalError() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }

  confirmAction() {
    this.closeModal();
  }

	initUser() {

		// initializes the user's info.
		let token = this._loginService.getCookie("auth_token");
		if (token) { // a session might exists. ->  redirects to select module.
		 this._router.navigate(['/select', {}]);
		}
	}

	parse_query_string(query) {
	  var vars = query.split("&");
	  var query_string = {};
	  for (var i = 0; i < vars.length; i++) {
	    var pair = vars[i].split("=");
	    var key = decodeURIComponent(pair[0]);
	    var value = decodeURIComponent(pair[1]);
	    // If first entry with this name
	    if (typeof query_string[key] === "undefined") {
	      query_string[key] = decodeURIComponent(value);
	      // If second entry with this name
	    } else if (typeof query_string[key] === "string") {
	      var arr = [query_string[key], decodeURIComponent(value)];
	      query_string[key] = arr;
	      // If third or later entry with this name
	    } else {
	      query_string[key].push(decodeURIComponent(value));
	    }
	  }
	  return query_string;
	}

	callRecoverForm(action: string){
    if(action === 'user') {
      this.actionRecover = 'usuario';
    } else if(action === 'password') {
      this.actionRecover = 'contraseña';
    }
		this.activeform.login = false;
	}

	callLoginForm(){
    this.loadingResetPass = true;
		this.activeform.login = true;
	}

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }


  loadResetPass() {
    this.loadingResetPass = !this.loadingResetPass;
    this.activeform.login = false;
  }

}
